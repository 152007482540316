<template>
  <div
    class="modal fade"
    :id="`exampleModal-` + modalId"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-backdrop="false"
    v-if="dialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ modalTitle }} ({{ modalSupplier }} -> {{ modalReceiver }})
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form @submit.prevent="stockCashFlowInsert">
          <div class="modal-body">
            <div class="form-group">
              <label>Account Code</label>
              <model-list-select
                v-model="form.account_code_id"
                :list="account_codes"
                option-value="id"
                option-text="name"
                placeholder="Select Account Code"
              >
              </model-list-select>
            </div>
            <div class="form-group">
              <label>Amount</label>
              <input type="number" class="form-control" v-model="form.amount" />
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea
                class="form-control"
                v-model="form.description"
                style="height:100px"
              ></textarea>
            </div>
            <div class="form-group">
              <label>Payment Type</label>
              <select v-model="form.payment_type_id" class="form-control">
                <option value="">Select Payment Type</option>
                <option
                  :value="payment_type.id"
                  v-for="(payment_type, index) in payment_types"
                  :key="index"
                >
                  {{ payment_type.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="isSubmitting"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../services/api";
import { PaymentTypeRepository } from "../../repositories/RepositoryFactory";

export default {
  props: ["modalTitle", "modalSupplier", "modalReceiver", "modalId"],
  data() {
    return {
      id: this.modalId,
      data: [],
      dialog: true,
      form: {
        account_code_id: "",
        amount: "",
        description: "",
        payment_type_id: "",
      },
      isSubmitting: false,
      errors: {},
      account_codes: [],
      payment_types: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      apiGet("stock_supplies/" + this.id).then((result) => {
        let cashflow = result.data.data.cashflow;
        this.form.account_code_id = cashflow.account_code_child_id;
        this.form.amount = cashflow.amount;
        this.form.description = cashflow.description;
        this.form.payment_type_id = cashflow.payment_type_id;
      });
      PaymentTypeRepository.get()
        .then((result) => {
          this.payment_types = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
      apiGet("stock_request/get_status").then((result) => {
        this.statuses = result.data;
      });
      apiGet("account_code/not-paginate").then((result) => {
        this.account_codes = result.data.data;
      });
    },
    stockCashFlowInsert() {
      this.isSubmitting = true;
      axios
        .post(
          this.$store.state.api + "stock_supplies/assign/" + this.id,
          this.form,
          {
            headers: {
              Authorization: this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$router.push({ name: "CashFlow" });
          this.$noty.success("Your Stock Supplies has been updated!");
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
